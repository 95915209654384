import { authentik } from "@ecca/vue-auth-plugin";
import { defineStore } from "pinia";
import { computed } from "vue";
import { RouteLocationNormalized } from "vue-router";
import { useTheme } from "vuetify";
import {
  THEME_BLUE,
  THEME_CYAN,
  THEME_GREEN,
  THEME_ORANGE,
} from "@/data/constants";
import { drawerClientItems } from "@/data/menu/drawerClientItems";
import { drawerDefaultItems } from "@/data/menu/drawerDefaultItems";
import { channelsRouteBasePath } from "@/modules/channels/routes";
import { contentRoutesNames } from "@/modules/content/routes";
import { productRoutesNames } from "@/modules/products/routes";
import { reportsRouteBasePath } from "@/modules/reports/routes";
import { initMessaging } from "@/plugins/fcm";
import router from "@/router";

export interface SnackbarProps {
  timeout?: number;
  closeIcon?: boolean;
  icon?: string;
  restrictToRoute?: string;
}

export interface Snackbar extends SnackbarProps {
  type: string;
  text: string;
  visible?: boolean;
}

export interface BreadcrumbsItem {
  text: string;
  link?: { name: string; to?: any; params?: any };
}

export const useAppStore = defineStore("ecca-main", {
  state: () => ({
    theme: "greenTheme" as string,
    notifications: [] as Snackbar[],
    drawer: {
      drawerItems: [],
      drawerOffset: 0 as number,
      drawerItemHovered: null as any,
    },
    breadcrumbs: {
      items: [] as BreadcrumbsItem[],
      isVisible: false as boolean,
    },
    errors: {
      api: false as boolean,
      notFound: false as boolean,
      forbidden: false as boolean,
    },
    editState: {
      isWorkInProgress: false as boolean,
      isWorkInProgressModalOpen: false as boolean,
    },
    cachedTargetRoute: {} as RouteLocationNormalized,
    isLoading: false,
    bottomActionBar: {
      isVisible: false,
      height: 0,
    },
    notificationDialogOpen: false as boolean,
  }),
  getters: {
    userInfo: () => authentik?.userInfo,
    apiError: (state) => state.errors.api,
    forbidden: (state) => state.errors.forbidden,
    notFound: (state) => state.errors.notFound,
    clientID: (state) =>
      computed({
        get(): string {
          return authentik.userInfo?.clientID ?? "";
        },
        set(value: string) {
          state.isLoading = true;
          router.push("/");
          authentik.authenticateForClient(value).then(() => {
            state.isLoading = false;
          });
        },
      }),
    getDefaultClientMenuItems: () => {
      if (useAppStore().auth("admin")) return drawerClientItems();
      if (useAppStore().auth("client", true)) return drawerClientItems();
    },
    getDefaultMenuItems: (): any[] => {
      if (useAppStore().auth("admin")) return drawerDefaultItems();

      const items = [] as any[];

      if (useAppStore().auth("products"))
        items.push({
          title: "Products",
          icon: "$Package",
          link: router.resolve({
            name: productRoutesNames.root,
          }),
          routeName: "products",
          theme: THEME_GREEN,
        });

      if (useAppStore().auth("reporting"))
        items.push({
          title: "Reports",
          icon: "$Strategy",
          link: reportsRouteBasePath,
          routeName: "reports",
          theme: THEME_CYAN,
        });

      if (
        useAppStore().auth("contents:skl") ||
        useAppStore().auth("contents:storyboards") ||
        useAppStore().auth("contents:rich") ||
        useAppStore().auth("contents:written_content")
      )
        items.push({
          title: "Content",
          icon: "$Template",
          link: router.resolve({
            name: contentRoutesNames.root,
          }),
          routeName: contentRoutesNames.root,
          theme: THEME_BLUE,
        });

      if (useAppStore().auth("channels"))
        items.push({
          title: "Channels",
          icon: "$ArrowsRightLeft",
          link: channelsRouteBasePath,
          routeName: "channels",
          theme: THEME_ORANGE,
        });

      return items;
    },
    getNotificationPermision: () => {
      return Notification.permission;
    },
  },
  actions: {
    setTheme(value: string) {
      const theme = useTheme();

      this.theme = value;
      theme.global.name.value = this.theme;
    },

    setApiError() {
      this.errors.api = true;
    },

    setForbiddenError(value: boolean) {
      this.errors.forbidden = value;
    },

    setNotFoundError() {
      this.errors.notFound = true;
    },

    resetErrors() {
      this.errors.api = false;
      this.errors.notFound = false;
    },

    setDrawerItems(value: any) {
      this.drawer.drawerItems = value;
    },

    setDrawerOffset(value: number) {
      this.drawer.drawerOffset = value;
    },

    setDrawerItemHovered(value: any) {
      this.drawer.drawerItemHovered = value;
    },

    addNotifications(item: Snackbar) {
      const notification = {
        ...item,
        visible: true,
      };

      if (
        this.notifications.some(
          (n: any) =>
            n.icon === notification.icon &&
            n.type === notification.type &&
            n.text === notification.text,
        )
      )
        return;

      this.notifications.push(notification);
    },

    hideNotifications() {
      this.notifications.forEach((s: any) => {
        s.visible = false;
      });
    },

    showNotifications() {
      this.notifications.forEach((s: any) => {
        s.visible = true;
      });
    },

    removeNotifications() {
      this.notifications.forEach((s: any, i) => {
        if (!s.visible) this.notifications.splice(i, 1);
      });
    },

    removeNotificationByText(text: string) {
      this.notifications.forEach((s: any, i) => {
        if (s.text.includes(text)) this.notifications.splice(i, 1);
      });
    },

    removeRestrictedNotifications(routeName: string) {
      let found = false;
      this.notifications.forEach((s: any) => {
        if (!s.restrictToRoute) return;
        if (s.restrictToRoute !== routeName) {
          s.visible = false;
          found = true;
        }
      });
      if (found) this.removeNotifications();
    },

    showNotificationDialog(value: boolean) {
      this.notificationDialogOpen = value;
    },

    logout() {
      authentik.logout();
    },

    setCachedTargetRoute(route: RouteLocationNormalized) {
      this.cachedTargetRoute = route;
    },

    setWorkInProgress(value: boolean) {
      this.editState.isWorkInProgress = value;
    },

    setIsWorkInProgressModalOpen(value: boolean) {
      this.editState.isWorkInProgressModalOpen = value;
    },

    auth: (baseScope: string, checkWrite?: boolean) => {
      const scope = authentik.userInfo?.scope ?? "";
      const scopeArr = typeof scope === "string" ? scope.split(" ") : scope;
      if (scopeArr.includes("admin")) return true;

      const readScope = `${baseScope}:read`;
      const writeScope = `${baseScope}:write`;

      if (checkWrite) {
        return scopeArr.includes(writeScope);
      } else {
        return (
          scopeArr.includes(readScope) ||
          scopeArr.includes(writeScope) ||
          scopeArr.includes(baseScope)
        );
      }
    },

    validateNotificationPermission: async () => {
      const notifications = await navigator.permissions.query({
        name: "notifications",
      });

      if (notifications.state !== "granted") {
        useAppStore().showNotificationDialog(true);
      } else {
        await initMessaging();
      }
    },
  },
});
