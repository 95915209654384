import { toValue } from "vue";
import { retryStrategy } from "@/api/config";
import { MutationOptions, QueryOptions } from "@/api/types";

export const defaultOptions: QueryOptions = {
  enabled: true,
  retry: retryStrategy,
  params: {},
};

export const useQueryOptions = (options: QueryOptions): QueryOptions => {
  return {
    ...defaultOptions,
    ...options,
  };
};

export const useMutationOptions = (options: MutationOptions) => {
  return {
    ...options,
  };
};

/// This function is used to generate additioanl params to Tanstack Query Key
export const useQueryKey = (options: QueryOptions): string => {
  const params = useQueryParams(options);
  return params.length > 1 ? `?${params}` : "";
};

// Convert query params object to a string
/**
 * Converts query options into a URL query parameter string.
 *
 * @param options - The query options object containing parameters for the request
 * @param options.params - Optional object containing query parameters
 * @param options.params.include - Optional include parameter to be resolved
 * @param options.params.queryStrings - Optional array of additional query strings
 *
 * @returns A string of URL query parameters joined with '&'
 *
 * @example
 * const options = {
 *   params: {
 *     include: ['user', 'posts'],
 *     queryStrings: ['sort=desc', 'limit=10']
 *   }
 * };
 * const queryString = useQueryParams(options);
 * // Returns: "include=user,posts&sort=desc&limit=10"
 */
export const useQueryParams = (options: QueryOptions): string => {
  const params = options.params || {};
  let queryParams: string[] = [];

  if (options.params?.include) {
    const includeParam = resolveIncludeParam(options);
    if (includeParam) {
      queryParams.push(includeParam);
      delete params.include;
    }
  }
  if (options.params?.queryStrings) {
    queryParams = [...queryParams, ...toValue(options.params.queryStrings)];
  }
  return queryParams.join("&");
};

const resolveIncludeParam = (options: QueryOptions) => {
  let include = toValue(options.params?.include);
  if (include?.length) {
    if (!Array.isArray(include)) {
      include = [include];
    }
    return `include=${include.join(",")}`;
  }
};
