import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";
import { storyboardsRoutesNames } from "@/modules/storyboards/routes";
import { useStoryboardsStore } from "@/modules/storyboards/store";
import { FCMMessage, FCMMessageDetails, IFCMMessage } from "@/modules/types";
import { useWrittenContentStore } from "@/modules/writtenContent/store";
import router from "@/router";
import { useSnackbar } from "@/shared/hooks/useSnackbar";

export const knownMessageTopics = [
  "ai_content_generation",
  "storyboard",
  "ai_translation",
];

export const useMsgStore = defineStore("ecca-msg", {
  state: () => ({
    messages: [] as IFCMMessage[],
    messagesByTopic: {} as Record<string, IFCMMessage[]>,
  }),
  getters: {},
  actions: {
    init() {
      const broadcastChannel = new BroadcastChannel("notifications");

      broadcastChannel.addEventListener(
        "message",
        ({ data }: MessageEvent<FCMMessage>) => {
          useMsgStore().FCMMessageReceived(data);
        },
      );
    },
    FCMMessageReceived(message: FCMMessage) {
      if (message.title === "" && message.message === "") return;

      try {
        const detailsParsed = JSON.parse(
          message.details ?? "{}",
        ) as FCMMessageDetails;
        const messageParsed = {
          ...message,
          detailsParsed,
        };

        if (import.meta.env.MODE !== "production")
          console.table({ ...message, ...detailsParsed });

        let topic = "unknown";
        if (
          detailsParsed?.topic &&
          knownMessageTopics.includes(detailsParsed?.topic)
        ) {
          topic = detailsParsed.topic;
        }

        if (detailsParsed?.topic === "ai_content_generation") {
          this.AIContentGenerationMessageReceived(messageParsed);
        } else if (detailsParsed?.topic === "ai_translation") {
          this.AIContentTranslationMessageReceived(messageParsed);
        } else {
          if (
            detailsParsed.storyboardId ||
            detailsParsed.topic === "storyboard"
          ) {
            if (this.messages.find((msg) => msg.message === message.message))
              return;
            topic = "storyboard";
            this.storyboardMessageReceived(messageParsed);
          }
        }
        // just log to have a record of messages
        if (detailsParsed?.status !== "success") {
          Sentry.captureMessage("AIContentGeneration failed", {
            level: "warning",
            extra: {
              detailsParsed,
            },
          });
        }

        if (!this.messagesByTopic[topic]) {
          this.messagesByTopic[topic] = [];
        }

        this.messages.push(messageParsed);
        this.messagesByTopic[topic].push(messageParsed);
      } catch (error) {
        throw new Error(`Error parsing FCMMessage: ${error}`);
      }
    },

    AIContentTranslationMessageReceived(message: IFCMMessage) {
      const { detailsParsed } = message;
      if (!detailsParsed.entity_id) return;

      const writtenContentStore = useWrittenContentStore();
      const storyboardsStore = useStoryboardsStore();

      if (detailsParsed?.type === "content_matrix") {
        if (detailsParsed.status === "in_progress") return;

        void storyboardsStore.setStoryboardAITaskSuccess(
          detailsParsed.entity_id,
          detailsParsed.status === "success",
        );
      }
      if (detailsParsed?.type === "written_content") {
        if (detailsParsed.status === "in_progress") return;

        void writtenContentStore.setWrittenContentAITaskSuccess(
          {
            writtenContentId: detailsParsed.entity_id,
            msg: detailsParsed.error,
            taskType: "translate",
          },
          detailsParsed.status === "success",
        );
      }
    },

    AIContentGenerationMessageReceived(message: IFCMMessage) {
      const { detailsParsed } = message;
      const writtenContentStore = useWrittenContentStore();
      const storyboardsStore = useStoryboardsStore();

      if (detailsParsed?.type === "content_matrix") {
        void storyboardsStore.setStoryboardAITaskSuccess(
          detailsParsed.entity_id,
          detailsParsed.status === "success",
        );
      }

      if (detailsParsed?.type === "written_content") {
        if (!detailsParsed.entity_id) return;
        if (detailsParsed.status === "in_progress") return;

        void writtenContentStore.setWrittenContentAITaskSuccess(
          {
            writtenContentId: detailsParsed.entity_id,
            msg: detailsParsed.error,
            taskType: "generate",
          },
          detailsParsed.status === "success",
        );
      }
    },

    storyboardMessageReceived(message: IFCMMessage) {
      const { detailsParsed } = message;
      const { pushNotification } = useSnackbar();

      if (detailsParsed.status.toLowerCase() === "success") {
        const link = router.resolve({
          name: storyboardsRoutesNames.details(),
          params: {
            storyboardId: detailsParsed.storyboardId,
          },
        });

        pushNotification(
          "success",
          `<p class="mb-2"><strong>${message.message}</strong></p><div><a href="${link.href}">See Content Matrix</a></div>`,
        );
      }
      if (detailsParsed.status === "failed") {
        if (detailsParsed.error === "failed to parse column") {
          pushNotification(
            "error",
            `<p class="mb-2"><strong>Generating Content Matrix has failed.</strong></p><p>Please make sure Column B in the Excel file is not empty and try uploading the file again. If the problem persists, please contact the Development Team.</p>`,
          );
        } else {
          pushNotification(
            "error",
            `<p class="mb-2"><strong>Generating Content Matrix has failed.</strong></p><p>Please, try again or contact the Development Team.</p>`,
          );
        }
      }
    },
  },
});
