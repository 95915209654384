export const errorRoutes: any = {
  path: "/:catchAll(.*)",
  component: () => import("@/shared/ui/layouts/DefaultLayout.vue"),
  meta: {
    auth: true,
  },
  children: [
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: () => import("@/shared/ui/views/errors/Error404.vue"),
    },
  ],
};
