import { useAppStore } from "@/store/app";

export type ErrorResolverType =
  | "silent"
  | "notification"
  | "error"
  | "default"
  | "silent-delete";
export type SilentDeleteErrorResolverType = { data: any } | void;

export const errorHandler = (error: any, errorResolver?: ErrorResolverType) => {
  if (!errorResolver || errorResolver === "default") {
    defaultErrorResolver(error);
  }
  return Promise.reject(error as Error);
};

export const silentDeleteErrorResolver = (
  error: any,
): SilentDeleteErrorResolverType => {
  const errorStatus = error?.response?.status;
  if (errorStatus && errorStatus === 400) {
    return {
      data: error.response.data.data,
    };
  }
  defaultErrorResolver(error);
};

export const defaultErrorResolver = (error: any) => {
  const appStore = useAppStore();
  const errorStatus = error?.response?.status;
  try {
    if (errorStatus) {
      if (errorStatus >= 500 && errorStatus < 600) {
        appStore.setApiError();
      } else if (
        errorStatus >= 404 &&
        errorStatus !== 406 &&
        errorStatus < 500
      ) {
        appStore.setNotFoundError();
      } else if (errorStatus === 403) {
        appStore.setForbiddenError(true);
      } else {
        throw new Error(error?.message || "Something went wrong");
      }
    } else {
      throw new Error(error?.message || "Something went wrong");
    }
  } catch (e: any) {
    appStore.addNotifications({
      type: error.name === "CanceledError" ? "warning" : "error",
      icon: "$TriangleExclamation",
      text: `${e?.message}`,
    });
  }
};

export const responseHandler = (response: any) => {
  return response;
};
