const sklRouteBaseName = "skl";

export const sklRoutesNames = {
  root: "skl",
  details: () => `${sklRoutesNames.root}.details`,
};

export const sklRoutes = (basePath: string): any => {
  const path = `${basePath}/${sklRouteBaseName}`;
  return {
    path,
    component: () => import("@/modules/skl/views/layouts/Default.vue"),
    meta: {
      auth: true,
      scope: ["contents:skl"],
      scrollTop: true,
    },
    children: [
      {
        path: `${path}/:id`,
        name: sklRoutesNames.details(),
        component: () => import("@/modules/skl/views/SklDetails.vue"),
      },
    ],
  };
};
