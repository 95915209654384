<script lang="ts" setup>
import { computed, onMounted } from "vue";
import {
  WrittenContentAITaskSuccessPayload,
  WrittenContentAITranslateTask,
} from "@/modules/types";
import { writtenContentRoutesNames } from "@/modules/writtenContent/routes";
import { useWrittenContentStore } from "@/modules/writtenContent/store";
import router from "@/router";
import { useSnackbar } from "@/shared/hooks/useSnackbar";

const task = defineModel<WrittenContentAITranslateTask>("taskItem", {
  required: true,
});
const dialogVisibleMV = defineModel("dialogVisible", { required: true });

const props = defineProps<{
  position: number;
  taskItem: WrittenContentAITranslateTask;
}>();

const writtenContentStore = useWrittenContentStore();

const itemName = computed(() => {
  return (
    props.taskItem.productName +
    " " +
    props.taskItem.writtenContentTargetData.name
  );
});

const { pushNotification } = useSnackbar();
writtenContentStore.$onAction(({ name, args }) => {
  if (name === "setWrittenContentAITaskSuccess") {
    const [{ writtenContentId, msg }, isSuccess] = args as [
      WrittenContentAITaskSuccessPayload,
      boolean,
    ];
    console.log("🚀 ~ writtenContentStore.$onAction ~ args:" + name, args);

    if (
      writtenContentId === task.value!.writtenContentData.id &&
      task.value.taskType === "translate"
    ) {
      task.value.isInProgress = false;
    }
    if (isSuccess) {
      const link = router.resolve({
        name: writtenContentRoutesNames.adaptations(),
        params: {
          productId: task.value.productId,
          writtenContentLocalizationId:
            task.value.writtenContentTargetData.parent_id,
          writtenContentAdaptationId: task.value.writtenContentTargetData.id,
        },
      }).href;

      pushNotification(
        "success",
        `<strong>Content “${itemName.value}” successfully translated.</strong> <br>
        <a href="${link}">See content</a>.
          `,
      );
      writtenContentStore.writtenContentUpdateByThor(
        task.value.writtenContentTargetData.id,
      );
      writtenContentStore.removeWrittenContentAITask(
        task.value.writtenContentData.id,
        "translate",
      );
    } else {
      // take the first part of error message ending with a dot or a colon
      const parsedError = msg && msg.match(/.*?[.]/);

      pushNotification(
        "error",
        `An error occurred while generating content for “${itemName.value}” using AI. ${parsedError ?? ""}`,
        {
          timeout: -1,
        },
      );
      writtenContentStore.removeWrittenContentAITask(
        task.value.writtenContentData.id,
        "translate",
      );
    }
  }
});

onMounted(() => {
  task.value.isInProgress = true;
});
</script>
<template>
  <EccaSnackbar
    v-model="dialogVisibleMV"
    :color="'info'"
    :prependIcon="'$StoreBuildingWarehouse'"
    class="processing-ai-snackbar pt-10"
    :style="`transform: translateY(${position * 120}px)`"
  >
    <div class="ecca-snackbar__text font-weight-bold mb-3">
      Content for {{ itemName }} is being translated.
    </div>
    <div>It can take up to 5 minutes.</div>
    <VProgressLinear
      class="processing-ai-snackbar__progress"
      indeterminate
      color="secondary"
    />
  </EccaSnackbar>
</template>

<style scoped>
.processing-ai-snackbar__progress {
  width: 100%;
  margin: 10px 0;
}
</style>
