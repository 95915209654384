import { defineStore } from "pinia";
import { MaybeRefOrGetter, toValue } from "vue";
import { ActionTypes } from "@/modules/templates/types";
import {
  IdType,
  IIntentPhase,
  IKeyword,
  WrittenContent,
  WrittenContentAITask,
  WrittenContentAiTaskTypes,
  WrittenContentAITaskSuccessPayload,
  WrittenContentAITranslateTask,
} from "@/modules/types";
import { IKeywordsCollection } from "@/modules/writtenContent/components/WrittenContentEditor/types";

export type DialogModes = `create` | `edit` | "delete";

export type DialogStatusType = {
  delete: boolean;
  details: {
    status: boolean;
    mode?: DialogModes;
  };
  createNewAdaptation: boolean;
  createNewLocalization: boolean;
  editLocalization: boolean;
  editAdaptation: boolean;
  sklTable: boolean;
  createNewSkl: boolean;
  createNewReco: boolean;
  aiGeneration: boolean;
  aiProcess: boolean;
  aiConfirmCancel: boolean;
  aiTranslate: boolean;
  deleteStrategyRecommendationFile: boolean;
  uploadStrategyRecommendation: boolean;
  updateConfigFields: boolean;
};

export type WrittenContentWidgetSection =
  | "skl"
  | "guidance"
  | "reco"
  | undefined;

interface IWrittenContentState {
  isSidePanelExpanded: boolean;
  aiTasks: (WrittenContentAITask | WrittenContentAITranslateTask)[];
  isAiGenerationOn: boolean;
  isAiGenerationInProgress: boolean;
  isAIGenerated: boolean;
  isAiGenerationPossible: boolean;
  isAiGenerationSuccess: boolean | null;
  isEditModeEnabled: boolean;
  isHighlightModeEnabled: boolean;
  isAiTranslationOn: boolean;
  isAiTranslationPossible: boolean;
  aiGenerate: {
    stateReason?: string;
  };
  aiTranslate: {
    stateReason?: string;
  };
  activeElementId: IdType | null;
  activeElementName: string;
  activeAdaptation: WrittenContent | undefined;
  dialogStatus: DialogStatusType;
  recommendationVisibility: boolean;
  dialogKey: number;
  dialogData: any;
  currentActionType?: ActionTypes;
  clickedElement?: WrittenContent;
  sklWidget: {
    isExpanded: boolean;
    dragSidePanel: boolean | null;
    widgetSection?: WrittenContentWidgetSection;
  };
  sklData: {
    raw: IKeyword[] | null;
    parsed: IKeywordsCollection[];
  };
  intentPhases: IIntentPhase[];
  intentPhasesToParse: IIntentPhase[];
  eCommercePerfectNameFields: eCommercePerfectNameFieldsType[];
}

export type eCommercePerfectNameFieldsType = {
  id: IdType;
  text: string;
  title: string;
  placeholder: string;
};

const initialState: IWrittenContentState = {
  isSidePanelExpanded: true,
  aiTasks: [],
  isAiGenerationOn: true,
  isAIGenerated: false,
  isAiGenerationSuccess: null,
  isAiGenerationPossible: false,
  isAiGenerationInProgress: false,
  isEditModeEnabled: true,
  isAiTranslationOn: false,
  isAiTranslationPossible: false,
  aiGenerate: {
    stateReason: "",
  },
  aiTranslate: {
    stateReason: "",
  },
  isHighlightModeEnabled: true,
  activeElementId: null,
  activeElementName: "",
  activeAdaptation: undefined,
  dialogKey: 0,
  recommendationVisibility: true,
  dialogStatus: {
    delete: false,
    details: {
      status: false,
    },
    createNewAdaptation: false,
    createNewLocalization: false,
    editLocalization: false,
    editAdaptation: false,
    sklTable: false,
    createNewSkl: false,
    createNewReco: false,
    aiGeneration: false,
    aiProcess: false,
    aiTranslate: false,
    aiConfirmCancel: false,
    updateConfigFields: false,
    uploadStrategyRecommendation: false,
    deleteStrategyRecommendationFile: false,
  },
  dialogData: undefined,
  sklWidget: {
    isExpanded: false,
    dragSidePanel: null,
  },
  sklData: {
    raw: null,
    parsed: [],
  },
  intentPhases: [],
  intentPhasesToParse: [],
  eCommercePerfectNameFields: [
    {
      id: "",
      text: "",
      title: "",
      placeholder: "",
    },
  ],
};

export const useWrittenContentStore = defineStore("ecca-writtenContent", {
  state: (): IWrittenContentState => ({ ...initialState }),
  getters: {
    getWrittenContentAITaskById: (state: IWrittenContentState) => {
      return (
        writtenContentID: MaybeRefOrGetter<string>,
        type?: WrittenContentAiTaskTypes["taskType"],
      ) =>
        state.aiTasks.find((task) => {
          if (type) {
            return (
              task.writtenContentData.id === toValue(writtenContentID) &&
              task.taskType === type
            );
          }
          return task.writtenContentData.id === toValue(writtenContentID);
        });
    },
    getWrittenContentTranslateTaskByTargetId:
      (state: IWrittenContentState) => (targetId: MaybeRefOrGetter<string>) =>
        state.aiTasks.find(
          (task) =>
            task.taskType === "translate" &&
            task.writtenContentTargetData.id === toValue(targetId),
        ),
    getIsAiGenerationPossible(state) {
      return state.isAiGenerationPossible;
    },
    getIsAiGenerationInProgress() {
      return (writtenContentID: MaybeRefOrGetter<string>) => {
        const activeTask = this.getWrittenContentAITaskById(writtenContentID);
        if (activeTask) {
          return activeTask.isInProgress && activeTask.taskType === "generate";
        }
        return null;
      };
    },
    isGenerationDisabled() {
      return (writtenContentID: MaybeRefOrGetter<string>) => {
        if (!this.getIsAiGenerationPossible) return true;
        if (this.getIsAiGenerationInProgress(writtenContentID)) return true;
        return false;
      };
    },
  },
  actions: {
    addWrittenContentAITask(item: WrittenContentAiTaskTypes) {
      const task = { ...item, visible: true };
      console.log("addWrittenContentAITask", task);
      this.aiTasks.push(task);
    },

    async setWrittenContentAITaskSuccess(
      payload: WrittenContentAITaskSuccessPayload,
      isSuccess: boolean,
    ) {
      return new Promise((resolve) => {
        const index = this.aiTasks.findIndex(
          (item: any) =>
            item.writtenContentId === payload.writtenContentId &&
            item.taskType === payload.taskType,
        );
        if (index !== -1) {
          this.aiTasks[index].isSuccess = isSuccess;
        }
        resolve(true);
      });
    },

    removeWrittenContentAITask(
      writtenContentID: IdType,
      type: WrittenContentAiTaskTypes["taskType"],
    ) {
      const index = this.aiTasks.findIndex(
        (item) =>
          item.writtenContentData.id === writtenContentID &&
          item.taskType === type,
      );
      if (index !== -1) {
        this.aiTasks.splice(index, 1);
      }
      return;
    },

    toggleSidePanel() {
      this.isSidePanelExpanded = !this.isSidePanelExpanded;
      if (this.sklWidget.isExpanded) {
        this.resetSklWidgetState();
      }
    },
    toggleSklWidget() {
      if (this.sklWidget.dragSidePanel === null)
        this.sklWidget.dragSidePanel = this.isSidePanelExpanded;

      this.sklWidget.isExpanded = !this.sklWidget.isExpanded;

      if (this.sklWidget.dragSidePanel)
        this.isSidePanelExpanded = !this.sklWidget.isExpanded;
    },
    hideWrittenContentRecommendations() {},
    showWrittenContentRecommendations() {},
    turnOnAiTranslation() {
      this.isAiTranslationOn = true;
    },
    turnOffAiTranslation() {
      this.isAiTranslationOn = false;
    },
    enableAIGenerationButton(reason?: string) {
      this.aiGenerate.stateReason = reason;
      this.isAiGenerationPossible = true;
    },
    disableAIGenerationButton(reason?: string) {
      this.aiGenerate.stateReason = reason;
      this.isAiGenerationPossible = false;
    },
    enableAiTranslationButton() {
      this.aiTranslate.stateReason = "";
      this.isAiTranslationPossible = true;
    },
    disableAiTranslationButton(reason?: string) {
      this.aiTranslate.stateReason = reason;
      this.isAiTranslationPossible = false;
    },
    turnOffAIGenerationButton() {
      this.isAiGenerationOn = false;
    },
    turnOnAIGenerationButton() {
      this.isAiGenerationOn = true;
    },
    setWCGeneratedWithAi(isAIGenerated: boolean) {
      this.isAIGenerated = isAIGenerated;
    },
    resetSidePanelState() {
      this.isSidePanelExpanded = true;
    },
    resetSklWidgetState() {
      this.sklWidget.isExpanded = false;
      this.sklWidget.dragSidePanel = null;
    },
    toggleHighlightMode() {
      this.isHighlightModeEnabled = !this.isHighlightModeEnabled;
    },
    setEditMode(value: boolean) {
      this.isEditModeEnabled = value;
    },
    setActiveWrittenContentElementId(id: IdType) {
      this.activeElementId = id;
    },
    resetActiveWrittenContentElementId() {
      this.activeElementId = null;
    },
    setActiveAdaptation(item?: WrittenContent) {
      this.activeAdaptation = item;
    },
    resetActiveAdaptation() {
      this.activeAdaptation = undefined;
    },
    resetAdapterCreatorDialogStatus() {
      this.dialogStatus = {
        ...initialState.dialogStatus,
      };
    },
    resetWrittenContentDialogStatus() {
      this.dialogKey++;
      this.dialogStatus = {
        ...initialState.dialogStatus,
      };
      this.dialogData = initialState.dialogData;
    },
    saveWrittenContent() {},
    exportWrittenContent() {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    writtenContentUpdateByThor(writtenContentId: IdType) {},
    setECommercePerfectNameFields(fields: eCommercePerfectNameFieldsType[]) {
      this.eCommercePerfectNameFields = fields;
    },
    openModal({
      type,
      item,
      actionType,
      mode,
      payload,
    }: {
      type: keyof DialogStatusType;
      item?: WrittenContent;
      actionType?: ActionTypes;
      mode?: `create` | `edit`;
      payload?: any;
    }) {
      this.resetWrittenContentDialogStatus();
      this.currentActionType = actionType;
      this.clickedElement = item;
      if (payload) this.dialogData = payload;

      if (type === "details") {
        this.dialogStatus[type].status = true;
        if (mode) this.dialogStatus[type].mode = mode;
      } else {
        this.dialogStatus[type] = true;
      }
    },
  },
});
