const storyboardsRouteBaseName = "storyboards";

export const storyboardsRoutesNames = {
  root: "storyboards",
  details: () => `${storyboardsRoutesNames.root}.details`,
  storyboard: () => `${storyboardsRoutesNames.root}.storyboard`,
  localizations: () => `${storyboardsRoutesNames.root}.localizations`,
  versions: () => `${storyboardsRoutesNames.root}.versions`,
  masterVersions: () => `${storyboardsRoutesNames.root}.masterVersions`,
};

export const storyboardsRoutes = (basePath: string): any => {
  const path = `${basePath}/${storyboardsRouteBaseName}`;
  return [
    {
      path,
      component: () =>
        import("@/modules/storyboards/views/layouts/Default.vue"),
      meta: {
        auth: true,
        scope: ["contents:storyboards"],
        breadcrumb: "Content",
        scrollTop: true,
      },
      children: [
        {
          path: `${path}`,
          name: storyboardsRoutesNames.root,
          component: () =>
            import("@/modules/storyboards/views/StoryboardsListTab.vue"),
          meta: {
            breadcrumb: "Content Matrices list",
          },
        },
        {
          path: `${path}/:storyboardId`,
          name: storyboardsRoutesNames.details(),
          component: () =>
            import("@/modules/storyboards/views/StoryboardsDetails.vue"),
          meta: {
            breadcrumb: "Content Matrices details",
          },
        },
      ],
    },
    {
      path,
      component: () =>
        import("@/modules/storyboards/views/layouts/Storyboard.vue"),
      meta: {
        auth: true,
        scope: ["contents:storyboards"],
      },
      children: [
        {
          path: `${path}/:storyboardId/storyboard`,
          name: storyboardsRoutesNames.storyboard(),
          component: () =>
            import("@/modules/storyboards/views/StoryboardView.vue"),
          meta: {
            label: "Master",
          },
        },
        {
          path: `${path}/:storyboardId/storyboard/localizations/:storyboardLocalizationId?`,
          name: storyboardsRoutesNames.localizations(),
          component: () =>
            import(
              "@/modules/storyboards/views/StoryboardLocalizationView.vue"
            ),
          meta: {
            label: "Localization",
          },
        },
        {
          path: `${path}/:storyboardId/storyboard/versions/:storyboardVersionId`,
          name: storyboardsRoutesNames.masterVersions(),
          component: () =>
            import("@/modules/storyboards/views/StoryboardVersionView.vue"),
          meta: {
            label: "Master",
          },
        },
        {
          path: `${path}/:storyboardId/storyboard/localizations/:storyboardLocalizationId/versions/:storyboardVersionId`,
          name: storyboardsRoutesNames.versions(),
          component: () =>
            import(
              "@/modules/storyboards/views/StoryboardLocalizationVersionView.vue"
            ),
          meta: {
            label: "Localization",
          },
        },
      ],
    },
  ];
};
