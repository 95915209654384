import { THEME_GREEN } from "@/data/constants";
import { clientRoutesNames } from "@/modules/client/routes";
import router from "@/router";

export const drawerClientItems = () => {
  return [
    {
      title: "Brands",
      icon: "$Brand",
      link: router.resolve({
        name: clientRoutesNames.brands.list(),
      }),
      routeName: clientRoutesNames.brands.list(),
      theme: THEME_GREEN,
    },
    {
      title: "Categories",
      icon: "$Categories",
      link: router.resolve({
        name: clientRoutesNames.categories.list(),
      }),
      routeName: clientRoutesNames.root,
      theme: THEME_GREEN,
    },
    {
      title: "eRetailers",
      icon: "$Shop",
      link: router.resolve({
        name: clientRoutesNames.retailers.list(),
      }),
      routeName: clientRoutesNames.retailers.list(),
      theme: THEME_GREEN,
    },
    {
      title: "Markets",
      icon: "$Location",
      link: router.resolve({
        name: clientRoutesNames.markets.list(),
      }),
      routeName: clientRoutesNames.markets.list(),
      theme: THEME_GREEN,
    },
  ];
};
